import styled, { css } from 'styled-components';

export const SliderContainer = styled.div`
  position: relative;
  background: var(--background);
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh - 45px);
`;

export const SliderWrapper = styled.div`
  position: ${(props) => (props.innerWidth < 800 ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const SliderItem = styled.div`
  position: relative;
  height: 100%;
  width: ${(props) => `${props.width}px` || '100%'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 40px;
`;

export const Navigation = styled.ul`
  position: absolute;
  bottom: 10px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const NavigationItem = styled.li`
  list-style: none;
  width: 4px;
  height: 4px;
  margin: 0 3px;
  background: var(--black);
  border-radius: 100%;
  cursor: pointer;
  margin: 7px;

  ${(props) =>
    props.active &&
    css`
      background: var(--black);
      width: 12px;
      height: 12px;
    `};
`;

export const Control = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  cursor: pointer;

  bottom: 25px;
`;

export const ControlLeft = styled(Control)`
  left: 0;
`;
export const ControlRight = styled(Control)`
  right: 0;
`;

export const Wrapper = styled.div`
  margin-top: 80px;
  margin-left: 10%;
  padding: 25px;
  background-color: var(--background);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  max-width: 450px;
  box-sizing: border-box;
  min-height: 80vh;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 40px 0;
  }

  h1 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 25px;
    margin-right: 40px;
  }

  div {
    width: 100%;
    margin-bottom: 25px;

    p,
    ul,
    li {
      font-size: 15px;
      line-height: 22px;
      color: var(--black);
      font-family: RalewayRegular;
      letter-spacing: 0.2px;
      font-weight: 400;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  div {
    flex: 1;
  }
`;
