import { graphql } from 'gatsby';
import React from 'react';
import { Logo } from '../../components/Logo';
import { SEO } from '../../components/SEO';
import { Slider } from '../../components/Slider/Slider';

export default function about({ data }) {
  return (
    <>
      <SEO title="Rumblewood commercial about" />
      <Logo color="black" />
      <Slider items={data.about.nodes} />
    </>
  );
}

export const query = graphql`
  {
    about: allSanityNarrativeAbout {
      nodes {
        id
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
