import React, { useState, useEffect, useReducer } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import chevronLeft from '../../assets/images/icons/chevron-left-black.png';
import chevronRight from '../../assets/images/icons/chevron-right-black.png';

import {
  SliderItem,
  SliderContainer,
  SliderWrapper,
  Navigation,
  NavigationItem,
  ControlLeft,
  ControlRight,
  Container,
  Wrapper,
} from './Styles';

function reducer(state, action) {
  switch (action.type) {
    case 'NEXT':
      return {
        ...state,
        currentIndex: state.currentIndex + (1 % state.items.length),
      };
    case 'PREV':
      return {
        ...state,
        currentIndex: state.currentIndex - (1 % state.items.length),
      };
    case 'GOTO':
      return {
        ...state,
        currentIndex: action.index,
      };
    case 'RESET':
      return { currentIndex: 0, currentPosition: 0 };

    default:
      return state;
  }
}

export const Slider = ({ items }) => {
  const [width, setWidth] = useState(2000);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [state, dispatch] = useReducer(reducer, {
    currentIndex: 0,
    items,
  });

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    if (window) {
      setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (
      touchStart - touchEnd > 100 &&
      state.currentIndex < state.items.length - 1
    ) {
      // do your stuff here for left swipe
      dispatch({ type: 'GOTO', index: state.currentIndex + 1 });
    }

    if (touchStart - touchEnd < -100 && state.currentIndex !== 0) {
      // do your stuff here for right swipe
      dispatch({ type: 'PREV' });
    }
  };

  return (
    <div>
      <SliderContainer className="slider-instance">
        <SliderWrapper
          width={width * state.items.length}
          innerWidth={width}
          style={{
            transform: `translateX(${-(state.currentIndex * width)}px)`,
            transition: 'transform ease-out 0.30s',
            width: `${width * state.items.length}px`,
          }}
          onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
          onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
          onTouchEnd={() => handleTouchEnd()}
        >
          {state.items.map((item, index) => (
            <Slide
              key={index}
              last={index === state.items.length - 1}
              index={index}
              item={item}
              dispatch={dispatch}
              snap={state.snap}
              width={width}
            >
              <BlockContent blocks={item._rawContent} />
            </Slide>
          ))}
        </SliderWrapper>

        <Navigation>
          {state.items.map((_, index) => (
            <NavigationItem
              active={index === state.currentIndex}
              onClick={() => dispatch({ type: 'GOTO', index })}
              key={`nav${index}`}
            />
          ))}
        </Navigation>
        <div>
          {state.currentIndex > 0 && (
            <ControlLeft onClick={() => dispatch({ type: 'PREV' })}>
              <img src={chevronLeft} alt="Arrow left" />
            </ControlLeft>
          )}

          {state.currentIndex < state.items.length - 1 && (
            <ControlRight onClick={() => dispatch({ type: 'NEXT' })}>
              <img src={chevronRight} alt="Arrow right" />
            </ControlRight>
          )}
        </div>
      </SliderContainer>
    </div>
  );
};

const Slide = ({ width, children }) => (
  <SliderItem width={width}>
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  </SliderItem>
);
